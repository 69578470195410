<template>
  <div class="relative h-15 z-100">
    <div class="fixed left-0 top-0 w-full bg-gray-100">
      <div
        class="container h-15 px-4 flex flex-row space-x-4 items-center justify-between mx-auto"
      >
        <nuxt-link to="/">
          <t-icon-logo class="w-40 h-7 cursor-pointer" />
        </nuxt-link>
        <!-- <div class="pt-1.5">
          <a-icon-notifications class="w-5 h-5 cursor-pointer" />
        </div> -->
      </div>
    </div>
  </div>
</template>
